import React from "react";
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import { Container, Row, Col } from "reactstrap";

const RefundPolicy = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div>
            <div className="mt-20">
              <Container>
                <Row className="justify-content m-t-40">
                  <Col lg="12" md="10" className="">
                    <h1 className="title font-bold">Refund Policy</h1>
                    <p>
                      At Steam Ideas, we are committed to ensuring customer satisfaction. There is <strong>No Return, No Refund Policy</strong> on our website.
                    </p>
                    <p>
                      A No Return, No Refund Policy informs customers that all sales are final, meaning they can't get a refund or return any products they purchase and that we do not provide refunds.
                    </p>
                    <p><br></br></p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default RefundPolicy;
