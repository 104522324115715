import React from 'react';
import {
    Container,
    Row,
    Col
} from 'reactstrap';

import img1 from '../../../assets/images/categories/diwali.jpg';
import img2 from '../../../assets/images/categories/merchandise.jpg';
import img3 from '../../../assets/images/categories/new-joinee.jpg';
import img4 from '../../../assets/images/categories/work.jpg';
import img5 from '../../../assets/images/categories/women.png';
import img6 from '../../../assets/images/categories/birthday.jpeg';
import img7 from '../../../assets/images/categories/gift.jpg';
import img8 from '../../../assets/images/categories/awards.jpg';

const categories = [
    { id: 1, title: 'Diwali Gift Hampers', imgPath: img1, desc: 'Celebrate the festive season with beautifully curated Diwali hampers filled with sweets, dry fruits, and personalized items that convey your warmest wishes.' },
    { id: 2, title: 'Personalized Merchandise', imgPath: img2, desc: 'Enhance your brand visibility with customized items like pens, diaries, notepads, bags, coasters, bottles, magnets, and t-shirts that your recipients will love to use.' },
    { id: 3, title: 'New Joinee Gift Sets', imgPath: img3, desc: 'Welcome new team members with a thoughtful gift set that includes essentials like a diary, pen, coffee mug, and a personalized note to make them feel right at home.' },
    { id: 4, title: 'Work Anniversary Gift Sets', imgPath: img4, desc: 'Celebrate employee milestones with a bespoke gift set that recognizes their contribution and shows appreciation for their dedication.' },
    { id: 5, title: 'Women’s Day Gifting Kits', imgPath: img5, desc: 'Honor the women in your organization with a special kit featuring empowering messages, self-care items, and personalized gifts that resonate.' },
    { id: 6, title: 'Birthday Cakes and Personalized Gift Hampers', imgPath: img6, desc: 'Make birthdays memorable with customized cakes and gift hampers that are as unique as the person receiving them.' },
    { id: 7, title: 'Gift Voucher Solutions', imgPath: img7, desc: 'Give the gift of choice with our versatile gift vouchers, perfect for clients, partners, and employees to select something they truly want.' },
    { id: 8, title: 'Personalized Awards and Certificates', imgPath: img8, desc: 'Recognize achievements with custom-designed awards and certificates that highlight excellence and show your appreciation.' },
];

const Collections = () => {
    return (
        <div>
            <div className="spacer bg-light" id="imgs-component">
                <Container>
                    <Row className="justify-content-center">
                        <Col md="7" className="text-center">
                            <h1 className="title font-bold">Our Collection</h1>
                            <h6 className="subtitle">From timeless classics to innovative designs, our collection is tailored to elevate every corporate occasion.</h6>
                        </Col>
                    </Row>
                </Container>
            </div>
            <Container className="spacer">
                <Row>
                    {categories.map((category) => (
                        <Col lg="3" className="m-b-40">
                            {/* <h6 className="card-subtitle"><code>.img-rounded</code></h6> */}
                            <div className="image-card"><img src={category.imgPath} alt="img" className="img-responsive img-thumbnail category-img" width="200" /></div>
                            <h4 className="font-medium m-t-30">{category.title}</h4>
                            <p className="m-t-15 m-b-0">{category.desc}</p>
                        </Col>
                    ))}
                </Row>
            </Container>
        </div>
    );
}

export default Collections;
