/* eslint-disable */
import React from 'react';
import { Container, Row, Col } from 'reactstrap';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className="footer4 b-t spacer m-b-10 p-b-0 bg-light">
            <Container>
                <Row>
                    <Col lg="6" md="6" className="m-b-30">
                    <h4> Steam Ideas</h4>
                    <h6>Reach out to us for any inquiries or requests.</h6>
                        <p><a href="mailto:info@bigmenu.in" className="link">info@bigmenu.in</a></p>
                        <div className="round-social light">
                            <a href="https://www.facebook.com/steamideas" target="_blank" className="link"><i className="fa fa-facebook"></i></a>
                            <a href="https://watext.me/steamideas" target="_blank" className="link"><i className="fa fa-whatsapp"></i></a>
                        </div>
                    </Col>
                </Row>
                <div className="f4-bottom-bar p-t-0">
                    <Row>
                        <Col md="12">
                            <div className="d-flex font-14 justify-content-between">
                                <div className="m-t-10 m-b-20 copyright">All Rights Reserved by Steam Ideas.</div>
                                <div className="links ms-auto m-t-10 m-b-10">
                                    <a href="/privacy-policy" className="p-10">Privacy Policy</a>
                                    <a href="/refund-policy" className="p-10">Refund Policy</a>
                                    <a href="/terms-and-conditions" className="p-10">Terms and Conditions</a>
                                    {/* <a href="/legal-disclaimer" className="p-10">Legal Desclaimer</a> */}
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    );
}
export default Footer;
