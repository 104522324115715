import React from "react";
import Header from "../components/header/header.jsx";
import Footer from "../components/footer/footer.jsx";
import { Container, Row, Col } from "reactstrap";

const LegalDisclaimer = () => {
  return (
    <div id="main-wrapper">
      <Header />
      <div className="page-wrapper">
        <div className="container-fluid">
          <div>
            <div className="mt-20">
              <Container>
                <Row className="justify-content m-t-40">
                  <Col lg="12" md="10" className="">
                    <h1 className="title font-bold">Legal Disclaimer</h1>
                    <p><strong>Last updated: July 23, 2024</strong></p>

                    <h3>General Information</h3>
                    <p>The information provided by Steam Ideas ("we," "us," or "our") on our website, <a href="https://steamideas.com/" target="_blank" rel="noreferrer">https://steamideas.com/</a> (the "Site"), is for general informational purposes only. All information on the Site is provided in good faith, and we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, or availability of any information on the Site.</p>

                    <h3>Professional Advice</h3>
                    <p>The information on this Site is not intended to be, and should not be construed as, professional advice. We strongly recommend consulting with a qualified professional before making any decisions based on the information provided on our Site.</p>

                    <h3>No Warranties</h3>
                    <p>Your use of the Site is at your own risk. The Site is provided on an "as-is" and "as-available" basis. We disclaim all warranties, whether express or implied, including but not limited to implied warranties of merchantability, fitness for a particular purpose, and non-infringement. We do not guarantee the Site’s availability, uninterrupted access, or that the Site will be error-free.</p>

                    <h3>External Links</h3>
                    <p>The Site may contain links to third-party websites or services that are not owned or controlled by Steam Ideas. We do not endorse or assume any responsibility for the content, privacy policies, or practices of any third-party websites or services. You acknowledge and agree that we shall not be liable for any damage or loss caused by or in connection with the use of any such external sites or services.</p>

                    <h3>Limitation of Liability</h3>
                    <p>In no event shall Steam Ideas, nor our directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from (i) your use of or inability to use the Site; (ii) any unauthorized access to or use of our servers and/or any personal information stored therein; (iii) any interruption or cessation of transmission to or from the Site; (iv) any bugs, viruses, trojan horses, or the like that may be transmitted to or through the Site; and/or (v) any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available via the Site, whether based on warranty, contract, tort (including negligence), or any other legal theory, whether or not we have been informed of the possibility of such damage.</p>

                    <h3>Changes to This Disclaimer</h3>
                    <p>We may update this Legal Disclaimer from time to time. We will notify you of any changes by posting the new Legal Disclaimer on this page and updating the "Last updated" date at the top of this page. We encourage you to review this Disclaimer periodically to stay informed about our legal notices.</p>

                    <h3>Contact Us</h3>
                    <p>If you have any questions about this Legal Disclaimer, please contact us:</p>
                    <ul>
                      <li><strong>By email:</strong> <a href="mailto:info@bigmenu.in">info@bigmenu.in</a></li>
                    </ul>

                    <p>Thank you for visiting Steam Ideas!</p>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default LegalDisclaimer;
